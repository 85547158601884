import React from "react"
import Layout from "../../components/layout/Layout"
import Date from "../../components/date/Date"
import Markdown from "../../components/markdown/Markdown"
import css from "./BlogPost.module.css"
import Section from "../../components/section/Section"

export default ({ pageContext: { url, node } }) => {
  return (
    <Layout
      type="HMF"
      className={css.blogPost}
      slug={`${url}/${node.slug}`}
      title={node.titulo}
      cover={node.portada?.file.url}
      theme="blog"
    >
      <Section className={css.header}>
        <div className={css.headerContainer}>
          <h1>{node.titulo}</h1>
          <Date date={node.fecha} />
          <img src={node.portada?.file.url} alt="img" />
          <span>Créditos foto</span>
        </div>
      </Section>
      <Section className={css.body}>
        <Markdown className={css.bodyContainer}>
          {node.cuerpo.childMarkdownRemark.html}
        </Markdown>
      </Section>
    </Layout>
  )
}
